import request from '@/utils/request'
import { parseEndpointCategory } from '../utils/filters'

export function createCategory(data, type) {
  return request({
    url: `${parseEndpointCategory(type)}`,
    method: 'post',
    data
  })
}

export function updateCategory(data, type) {
  return request({
    url: `${parseEndpointCategory(type)}`,
    method: 'patch',
    data
  })
}

export function getCategory(params, type) {
  return request({
    url: `${parseEndpointCategory(type)}`,
    method: 'get',
    params
  })
}

export function deleteCategory(params, type) {
  return request({
    url: `${parseEndpointCategory(type)}`,
    method: 'delete',
    params
  })
}
